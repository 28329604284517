<template>
    <Head :title="$t('public.one_liner')" />

    <div class="space-y-32 page-container">
        <div class="grid gap-8 mx-auto grid-cols1 md:grid-cols-2">
            <div class="w-full space-y-8">
                <h1>{{ $t('public.one_liner') }}</h1>
                <p class="body-text">
                    {{ $t('public.hero_text_1') }}
                    <br />
                    {{ $t('public.hero_text_2') }}
                </p>
                <Link
                    :href="route('dashboard.index')"
                    class="btn btn-primary btn-block md:btn-wide md:btn-lg"
                >
                    <RocketLaunchIcon class="w-5 text-lg" />
                    {{ $t('navigation.get_started') }}
                </Link>
            </div>
            <div
                class="w-full h-auto bg-white bg-right bg-cover aspect-square rounded-2xl"
                :style="{
                    backgroundImage: `url(${heroImageUrl})`,
                }"
            ></div>
        </div>

        <div>
            <div class="flex flex-col flex-wrap w-full mb-16 md:flex-row">
                <div class="flex-1">
                    <h2 class="h3">{{ $t('public.trending_sneakers') }}</h2>
                    <p class="body-text">
                        {{ $t('public.trending_sneakers_text') }}
                    </p>
                </div>
                <div class="flex-none">
                    <Link
                        :href="route('marketplace.index')"
                        class="btn btn-primary btn-block md:btn-wide btn-outline"
                    >
                        {{ $t('navigation.discover_sneakers') }}
                    </Link>
                </div>
            </div>

            <div
                class="grid grid-cols-1 gap-8 mb-16 md:grid-cols-2 lg:grid-cols-3"
            >
                <ProductCard
                    v-for="product in trendingSneakers"
                    :key="product.sku"
                    :product="product"
                    :card-url="
                        route('marketplace.show', { product: product.slug })
                    "
                >
                    <i18n-t
                        v-if="product.bestOffer"
                        keypath="product.starting_from"
                        scope="global"
                    >
                        <template #amount>
                            <FormattedValue
                                currency="EUR"
                                :value="product.bestOffer.lowest_pair_price"
                            />
                        </template>
                    </i18n-t>
                </ProductCard>
            </div>
        </div>

        <div>
            <div class="flex flex-col flex-wrap w-full mb-16 md:flex-row">
                <div class="flex-1">
                    <h2 class="h3">{{ $t('public.top_sellers') }}</h2>
                    <p class="body-text">
                        {{ $t('public.top_sellers_text') }}
                    </p>
                </div>
                <div class="flex-none">
                    <Link
                        :href="route('marketplace.index')"
                        class="btn btn-primary btn-block md:btn-wide btn-outline"
                    >
                        {{ $t('public.view_offers') }}
                    </Link>
                </div>
            </div>

            <div
                class="grid grid-cols-1 gap-8 mb-16 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
            >
                <TopSellerCard
                    v-for="(seller, sellerIndex) in topSellers"
                    :key="seller.sqid"
                    :seller="seller"
                    :rank="sellerIndex + 1"
                />
            </div>
        </div>
        <div>
            <h2 class="!mb-16 h3">{{ $t('public.browse_sneaker_models') }}</h2>

            <div
                class="grid grid-cols-1 gap-8 mb-16 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
            >
                <SneakerModelCard
                    v-for="(sneakerModel, index) in sneakerModels"
                    :key="index"
                    :sneaker-model="sneakerModel"
                />
            </div>
        </div>

        <div>
            <h2 class="h3">{{ $t('public.how_it_works.title') }}</h2>
            <p class="mb-16 body-text">{{ $t('public.how_it_works.text') }}</p>

            <div class="grid grid-cols-1 gap-8 mb-16 md:grid-cols-3">
                <div
                    class="flex flex-col items-center justify-between p-8 space-y-4 text-center card"
                >
                    <MagnifyingGlassMoneyIcon class="w-full h-auto max-w-60" />
                    <div>
                        <div class="h5">
                            {{ $t('public.how_it_works.card_1.title') }}
                        </div>
                        <div>{{ $t('public.how_it_works.card_1.text') }}</div>
                    </div>
                </div>
                <div
                    class="flex flex-col items-center justify-between p-8 space-y-4 text-center card"
                >
                    <ListRadioIcon class="w-full h-auto max-w-60" />
                    <div>
                        <div class="h5">
                            {{ $t('public.how_it_works.card_2.title') }}
                        </div>
                        <div>{{ $t('public.how_it_works.card_2.text') }}</div>
                    </div>
                </div>
                <div
                    class="flex flex-col items-center justify-between p-8 space-y-4 text-center card"
                >
                    <MoneyIcon class="w-full h-auto max-w-60" />
                    <div>
                        <div class="h5">
                            {{ $t('public.how_it_works.card_3.title') }}
                        </div>
                        <div>{{ $t('public.how_it_works.card_3.text') }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Head, Link } from '@inertiajs/vue3';
import DefaultLayout from '@/Layouts/DefaultLayout.vue';
import heroImageUrl from '@assets/images/hero.jpg';
import RocketLaunchIcon from '@assets/icons/RocketLaunch.svg?component';
import MagnifyingGlassMoneyIcon from '@assets/icons/MagnifyingGlassMoney.svg?component';
import ListRadioIcon from '@assets/icons/ListRadio.svg?component';
import MoneyIcon from '@assets/icons/Money.svg?component';
import ProductCard from '@/Components/ProductCard.vue';
import SneakerModelCard from '@/Components/SneakerModelCard.vue';
import FormattedValue from '@/Components/FormattedValue.vue';
import TopSellerCard from '@/Components/TopSellerCard.vue';

export default {
    components: {
        Head,
        Link,
        RocketLaunchIcon,
        ProductCard,
        SneakerModelCard,
        MagnifyingGlassMoneyIcon,
        ListRadioIcon,
        MoneyIcon,
        FormattedValue,
        TopSellerCard,
    },
    layout: DefaultLayout,
    props: {
        trendingSneakers: {
            type: Array,
            required: true,
        },
        topSellers: {
            type: Array,
            required: true,
        },
        sneakerModels: {
            type: Array,
            required: true,
        },
    },
    computed: {
        heroImageUrl() {
            return heroImageUrl;
        },
    },
};
</script>
