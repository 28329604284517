<template>
    <Link :href="cardUrl" class="w-full">
        <div class="relative w-full">
            <div
                class="flex items-center justify-center w-full overflow-hidden bg-white aspect-square rounded-t-2xl"
            >
                <img :src="sneakerModel.image" class="w-full" />
            </div>
        </div>
        <div class="px-8 py-4 bg-neutral rounded-b-2xl">
            <p class="font-bold h5">{{ sneakerModel.name }}</p>
        </div>
    </Link>
</template>

<script>
import { Link } from '@inertiajs/vue3';

export default {
    name: 'SneakerModelCard',
    components: {
        Link,
    },
    props: {
        sneakerModel: {
            type: Object,
            required: true,
        },
    },
    computed: {
        cardUrl() {
            return route('marketplace.index', {
                query: this.sneakerModel.name,
            });
        },
    },
};
</script>
