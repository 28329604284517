<template>
    <Link
        :href="cardUrl"
        class="flex flex-col items-center justify-center p-4 card"
    >
        <div
            class="flex items-center justify-center w-32 h-32 mb-4 overflow-hidden rounded-full avatar"
            :class="{
                'bg-base-100': !userProfile?.avatar_image,
            }"
        >
            <img
                v-if="userProfile?.avatar_image"
                :src="userProfile.avatar_image"
            />
            <UserIcon v-else class="w-20 h-20 text-gray" />
        </div>
        <div
            v-if="rank"
            class="absolute flex items-center justify-center w-8 h-8 rounded-full top-4 left-4 bg-base-100 text-gray"
        >
            {{ rank }}
        </div>
        <p class="h5 mb-2 font-bold">{{ seller.name }}</p>
        <p class="text-gray">
            {{ $t('profile.pairs_sold') }}:
            <span class="text-white">{{ seller.stats.soldWithinMonth }}</span>
        </p>
    </Link>
</template>

<script>
import { Link } from '@inertiajs/vue3';
import UserIcon from '@assets/icons/User.svg?component';

export default {
    name: 'TopSellerCard',
    components: {
        Link,
        UserIcon,
    },
    props: {
        seller: {
            type: Object,
            required: true,
        },
        rank: {
            type: Number,
        },
    },
    computed: {
        userProfile() {
            return this.seller.userProfile;
        },
        cardUrl() {
            return route('users.show', {
                user: this.seller.sqid,
            });
        },
    },
};
</script>
